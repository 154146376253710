export const teampls = [
    {
        name: "Deborah Hannon",
        image: "/img/profilesPic/deborah.jpg",
        email:"deborah@mypls.com",
        title: "President",
        paragraphs: [
            {
                text: "Deborah’s involvement in Pennsylvania state politics began with her employment in the House of Representatives as the Lead Technical Training Developer in charge of the design and implementation of all computer technology seminars. She also worked with a national consulting company as a Legislative Policy Specialist monitoring both state and federal legislation. In 2006, Deborah took her legislative and computer experience to PLS and joined PLS’s Sales and Marketing team eventually becoming the Vice President. Deborah’s small team of individuals dedicated themselves to growing the company client base while working to implement additional tools and services necessary in staying knowledgeable in an ever-changing legislative and technical environment. Having a degree in Education and a passion for politics, Deborah really enjoys working in Harrisburg alongside other legislative professionals. As President of PLS, she spends much of her time visiting with clients to learn about their job in an effort to continue to move PLS forward and build new resources.",
            },{
                text:"A Long Island native and graduate of Concordia College, New York, Deborah enjoys spending time visiting friends and family on the North Fork with her husband, Sean of almost thirty years, two children, Aaron and Ashley and small papillon puppy, Gerald. She also enjoys researching real estate and became a Pennsylvania licensed Realtor in 2010."
            },
            {
                text:"“I am passionate about my faith, my family, my job and the amazing clients that make this such an exciting experience. I also believe that the PLS team of hard working and dedicated professionals that I have the privilege of working with each and every day, are truly the backbone of this company’s success.”",
                italic: true,
            }
        ]
    },
    {
        name:"Amyra Weiss",
        image:"/img/profilesPic/Amyra.jpg",
        email:"amyra@mypls.com",
        title:"Vice President of Sales and Marketing",
        paragraphs: [
            {
                text:"Amyra joined PLS in the Spring of 2017, after being previously employed as a legal assistant specializing in civil rights and appellate litigation. She brings her strengths of working with clients and managing a fast-paced environment to the Sales and Marketing Team. She was born and raised in Connecticut and holds a degree in Political Science from the University of Connecticut, where she was a division one rower.",
            },{
                text:"Amyra enjoys traveling to visit family in Egypt or visiting other beach destinations, antiquing, thrift shopping, and spending her time with her two daughters."
            }
        ]
    },
    {
        name:"Katie Bollinger",
        image:"/img/profilesPic/Katie.jpg",
        email:"katie.bollinger@mypls.com",
        title:"Client Services Representative",
        paragraphs: [
            {
                text:"Katie Bollinger, born and raised in Camp Hill, has been a part of the PLS team since May 2023.",
            },{
                text:"With a decade of experience in sales, Katie is a seasoned professional dedicated to helping clients understand the ins and outs of government relations. As a member of the PLS team, she specializes in providing tailored solutions and expert guidance to ensure clients maximize the benefits of our services."
            },{
                text:"Outside of PLS, Katie finds joy in guiding others towards wellness as a Pilates instructor.  Her passion for fitness extends to the great outdoors, where she can be found running or hiking."
            }
        ]
    },
    {
        name:"Derek Danneker",
        image:"/img/profilesPic/Derek.jpg",
        email:"derek.danneker@mypls.com",
        title:"Legislative Reporter",
        paragraphs: [
            {
                text:"Derek Danneker joined PLS in August 2020 as a Legislative Reporter.",
            },
            {
                text:"Derek graduated from Lock Haven University in 2018 where he earned a Bachelor’s degree in Political Science and was Editor-in-Chief of the school’s newspaper, the Eagle Eye. In his student journalism days, he was an advocate for his fellow students and called attention to the university’s lack of effective student government, low counselor-student ratios, elimination of the French language program and other issues."
            },{
                text:"Prior to joining PLS, he worked at the Lock Haven Express before moving to their larger sister-paper, the Williamsport Sun-Gazette. Throughout his time there he covered county and municipal government, school boards, the local court system and police/fire activities."
            },{
                text:"Derek enjoys photography, reading philosophy and history, and exploring Southern Pennsylvania."
            }
        ]
    },
    {
        name:"Grace Kline",
        image:"/img/profilesPic/Grace.jpg",
        email:"Grace.Kline@MyPLS.com",
        title:"Associate Editor/Legislative Reporter",
        paragraphs: [
            {
                text:"Grace joined PLS in November 2021 as a Legislative Reporter after earning her bachelor’s degree in Communications and a minor in Political Science. With several years of experience secured through internships and journalism, Grace became the Associate Editor at PLS. She uses her skills in analyzing complex issues and delivering comprehensive coverage to ensure journalist standards are met on the Reporting Team.",
            },{
                text:"In her free time, Grace enjoys traveling, photography and spending time with friends and family."
            }
        ]
    },
    {
        name:"Maggie Foight",
        image:"/img/profilesPic/Maggie.jpg",
        email:"maggie.foight@mypls.com",
        title:"Legislative Reporter",
        paragraphs: [
            {
                text:"Maggie Foight joined PLS in May 2023 as a Legislative Reporter.",
            },{
                text:"Maggie graduated from Kenyon College in May 2023 with a bachelor’s degree in English, a minor in Spanish and a concentration in Public Policy. During her time at Kenyon, Maggie interned with Essential Data Corporation, Great Lakes Publishing Custom Media, Streamline Teams and SwimSwam Partners, LLC. She also briefly wrote for the Kenyon College Collegian and The Reading Eagle."
            },{
                text:"In college, Maggie swam competitively and spent weekends lifeguarding, coaching a local youth team and teaching swim lessons to all ages.  Now a “swammer,” Maggie enjoys reading, family game nights and spending time with friends."
            }
        ]
    },
    {
        name:"Kyle Purchase",
        image:"/img/profilesPic/kayle.jpg",
        email:"kyle.purchase@mypls.com",
        title:"Legislative Reporter",
        paragraphs: [
            {
                text:"Kyle Purchase joined PLS in February 2024 as a Legislative Reporter.",
            },{
                text:"Kyle graduated from Widener University in May 2017 with a bachelor’s degree in political science/international relations, as well as Temple University in May 2019 with a master’s in public policy. During his time at Widener, Kyle participated in intramural sports, interned with the Montgomery County District Attorney’s Office, and participated in community/charitable events with his fraternity. He also briefly wrote for the Widener chapter of the Odyssey Online."
            },{
                text:"Prior to joining PLS, he worked at a state representative’s office and for a background screening firm."
            },{
                text:"Kyle enjoys reading history, playing soccer, spending time with family, researching family ancestry, and exploring Civil War battlefields."
            }
        ]
    },
    {
        name:"Zoe Buchmann",
        image:"/img/profilesPic/Zoe.jpg",
        email:"Zoe.Buchmann@mypls.com",
        title:"Legislative Reporter",
        paragraphs: [
            {
                text:"Zoe joined PLS in September 2022 as a legislative reporter.",
            },{
                text:"In 2022, Zoe graduated from Elizabethtown College with a bachelor’s degree in Political Science and a minor in international relations and cognitive science. Throughout her time at school, she participated in various research projects on American government, politics, and foreign relations, and worked as a scribe for the Foreign Policy Association of Central Pennsylvania."
            },{
                text:"In her free-time, Zoe enjoys going to concerts, traveling to Germany to visit family, and spending time outside with her dog."
            }
        ]
    },
    {
        name:"Nicholas Wojdak",
        image:"/img/profilesPic/nicholas.png",
        email:"nicholas.wojdak@mypls.com",
        title:"Client Services Representative",
        paragraphs: [
            {
                text:"Nick is a Client Services Representative at Pennsylvania Legislative Services, bringing a wealth of experience and a passion for excellence to the team.",
            },{
                text:"A proud alum of Trinity College in Hartford, CT, Nick majored in Political Science, and interned in the United States Senate in Washington developing a deep understanding of the political landscape that is invaluable in his current role. Over the past five years, Nick has built a successful career in SaaS sales, demonstrating a knack for building strong client relationships and delivering outstanding service."
            },{
                text:"At Pennsylvania Legislative Services, Nick is dedicated to assisting both current and new clients, ensuring they receive top-notch support and tailored solutions to meet their unique needs."
            },{
                text:"Outside of work, Nick is an avid fitness enthusiast and enjoys a variety of hobbies including golf, hiking, skiing, and scuba diving. His active lifestyle and adventurous spirit reflects his energetic and proactive approach to both personal and professional pursuits."
            }
        ]
    },
    {
        name:"Daniel Sachetta",
        image:"/img/profilesPic/daniel.jpg",
        email:"daniel.sachetta@mypls.com",
        title:"Legislative Reporter",
        paragraphs: [
            {
                text:"Daniel Sachetta joined PLS in September 2023 as a Legislative Reporter.",
            },{
                text:"Daniel graduated from the University of Pittsburgh in April 2023 with a bachelor’s degree in Environmental Studies and minors in Political Science and Italian. During his time at school, he was a part of the men’s club water polo team, Italian Club, and a member of the Phi Alpha Delta Pre-Law Fraternity."
            },{
                text:"In his free time, Dan enjoys hiking, traveling, going to concerts and spending time with family and friends."
            }
        ]
    },
    {
        name:"Hattie Collier",
        image:"/img/profilesPic/hattie.jpg",
        email:"hattie.enterline@mypls.com",
        title:"Legislative Data Specialist",
        paragraphs: [
            {
                text:"Hattie joined PLS in February 2021 as a Legislative Data Specialist.",
            },{
                text:"Hattie is a native to the Harrisburg area and graduated from Lock Haven University in 2020 where she earned her Bachelor’s degree in Political Science. While attending LHU, she interned for the PA House of Representatives and served as Vice President of the French club on campus."
            },{
                text:"Hattie enjoys kayaking and camping, lifting, and listening to podcasts on topics from history to wellness and fitness."
            }
        ]
    },
    {
        name:"Samantha Rockhill",
        image:"/img/profilesPic/samantha.jpg",
        email:"sam.rockhill@mypls.com",
        title:"Legislative Data Specialist",
        paragraphs: [
            {
                text:"Sam joined PLS in April of 2021 as a Legislative Data Specialist.",
            },{
                text:"She graduated from Messiah University in May of 2020, where she earned a bachelor’s degree in Political Science and minored in Gender Studies. While at school she served on student government, was involved in several clubs and on-campus advocacy, and worked as both a community service leader and school Development Ambassador."
            },{
                text:"Prior to joining PLS Sam interned as a Legislative Fellow in the PA House of Representatives and worked as a Field Organizer during the 2020 general election."
            },{
                text:"In her free time, Sam enjoys spending time with loved ones, reading, and hiking."
            }
        ]
    },
    {
        name:"Christian Cusack",
        image:"/img/profilesPic/Christan.jpg",
        email:"christian.cusack@mypls.com",
        title:"Legislative Data Specialist",
        paragraphs: [
            {
                text:"Christian joined PLS in February 2023 as a Legislative Data Specialist.",
            },{
                text:"He graduated with a Bachelor’s in Political Science at West Chester University in 2022. While at school, he was a district office intern for the PA House of Representatives and earned minors in Spanish and African American studies. His senior thesis examined the electoral outcomes of New Zealand’s 1996 switch to Mixed-Member Proportional representation."
            },{
                text:"His hobbies include weightlifting, playing soccer, and studying politics and public policy."
            }
        ]
    },
    {
        name:"Julia Walters",
        image:"/img/profilesPic/julia.jpg",
        email:"julia.walters@mypls.com",
        title:"Legislative Reporter",
        paragraphs: [
            {
                text:"Julia joined PLS in October 2024 as a Legislative Reporter Analyst.",
            },{
                text:"She graduated from Millersville University in 2020 with a Bachelor’s degree in Digital Journalism. While at Millersville, she was part of the University Honors College where she wrote and defended a thesis on social media’s effect on those with pre-existing anxiety disorders. She was also heavily involved with the student-run newspaper, The Snapper, where she worked her way up from staff writer to managing editor."
            },{
                text:"Prior to working for PLS, Julia has worked as a legal editor for Social Security Administration hearings and interned with Susquehanna Style Magazine."
            },
            {
                text:"In her spare time, Julia likes to unwind by reading, listening to music, hanging out with family, and playing video games."
            }
            
        ]
    },
]




